import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { formStore } from "../State/state";

export default function BackDropLoading() {
  const backDrop: boolean = formStore((state) => state.backdrop);

  return (
    <div>
      <Backdrop
        sx={{ color: "#54AE36", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
