/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  token: "",
  signin(email: string, password: string, callback: VoidFunction) {
    const data = { email: email, password: password };

    fetch(URL + "/form/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        fakeAuthProvider.isAuthenticated = true;
        fakeAuthProvider.token = data.token;
        console.log("Success:", data);
      })
      .catch((error) => {
        fakeAuthProvider.isAuthenticated = false;
        console.error("Error:", error);
      });

    // setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export { fakeAuthProvider };
