import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function NotRegisteringHint() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Ihre Änderungen können Sie innerhalb von 30 Tagen vornehmen und mit
          einem Klick auf „SICHERN OHNE REGISTRIERUNG“ an uns frei geben.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Ihre geänderten Daten werden gespeichert und von uns nach einem
          Qualitäts-Check auf www.camp24checkin.de veröffentlicht.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Weitere Änderungen nach Ablauf der 30 Tage sind ohne Registrierung
          nicht möglich.
        </Typography>
      </Grid>
    </Grid>
  );
}
