import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material//TextField";
import Typography from "@mui/material//Typography";
import { persons, includes, yesno, threeChoices } from "../Data/dropdowns";
import MenuItem from "@mui/material//MenuItem";
import { useEffect } from "react";
import { formStore, FormState } from "../State/state";
import { IconButton } from "@mui/material/";
import ReplayIcon from "@mui/icons-material/Replay";
import { priceLoadSecondDropdownType } from "../Types/types";
import {
  priceLoadSecondDropdown,
  priceLoad,
  // priceLoadStatus,
} from "../State/models";
import PriceHint from "./Hints/pricehint";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function Price() {
  const updatePrice: FormState["updatePrice"] = formStore(
    (state) => state.updatePrice
  );
  const price: FormState["price"] = formStore((state) => state.price);
  const admin: FormState["admin"] = formStore((state) => state.admin);
  const customer: FormState["customer"] = formStore((state) => state.customer);
  const priceOld: FormState["priceOld"] = formStore((state) => state.priceOld);
  const priceLoadStatus: FormState["priceLoadStatus"] = formStore(
    (state) => state.priceLoadStatus
  );
  const edit: FormState["edit"] = formStore((state) => state.edit);
  const submitted: FormState["submitted"] = formStore(
    (state) => state.submitted
  );
  const [status, setStatus] = React.useState(priceLoadStatus);
  const [zPerson, setzPerson] = React.useState(false);
  const [szPerson, setszPerson] = React.useState(false);
  const [pPerson, setpPerson] = React.useState(false);
  const [pChild, setpChild] = React.useState(false);
  const [secondDropdown, setSecondDropdown] = React.useState(
    priceLoadSecondDropdown
  );
  const minWidth = "120px";

  useEffect(() => {
    setStatus(priceLoadStatus);
    setSecondDropdown(priceLoadSecondDropdown);
  }, []);
  const changeSix = (
    event: { target: { value: string; name: string } },
    ex: keyof priceLoadSecondDropdownType
  ) => {
    // if (event.target.value === "Ja") {
    //   // ts-ignore
    //   if (secondDropdown[ex] === false) {
    //     setSecondDropdown({ ...secondDropdown, [ex]: true });
    //     updatePrice(ex, "inklusive");
    //   } else {
    //     updatePrice(ex, event.target.value);
    //   }
    // } else {
    //   setStatus({ ...status, [ex]: false });
    //   updatePrice(ex, event.target.value);
    // }
    if (event.target.value !== "Nein" && event.target.value !== "inklusive") {
      setStatus({ ...status, [ex]: false });
    } else {
      setSecondDropdown({ ...secondDropdown, [ex]: false });
    }
    updatePrice(ex, event.target.value);
  };

  const zeroornot = (e: string) => {
    const ev = e.trim();
    if (
      ev === "0" ||
      ev === "0,00" ||
      ev === "0.00" ||
      ev === "0,0" ||
      ev === "0.0"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleBlur = (event: { target: { value: string; name: string } }) => {
    switch (event.target.name) {
      case "properson":
        if (zeroornot(event.target.value)) {
          updatePrice("proPerson", "inklusive");
        }
        break;
      case "zweipersonen":
        if (zeroornot(event.target.value)) {
          updatePrice("zweipersonen", "Kostenlos");
        }
        break;
      case "sidezweipersonen":
        if (zeroornot(event.target.value)) {
          updatePrice("sidezweipersonen", "Kostenlos");
        }
        break;
      case "prokid":
        if (zeroornot(event.target.value)) {
          updatePrice("proKid", "inklusive");
        }
        break;
      case "propet":
        if (zeroornot(event.target.value)) {
          updatePrice("proPet", "inklusive");
        }
        break;
      case "taxperson":
        if (zeroornot(event.target.value)) {
          updatePrice("taxPerson", "inklusive");
        }
        break;
      case "taxkid":
        if (zeroornot(event.target.value)) {
          updatePrice("taxKid", "inklusive");
        }
        break;
      case "taxpet":
        if (zeroornot(event.target.value)) {
          updatePrice("taxPet", "inklusive");
        }
        break;
      case "electricity":
        if (zeroornot(event.target.value)) {
          updatePrice("electricity", "inklusive");
        }
        break;
      case "water":
        if (zeroornot(event.target.value)) {
          updatePrice("water", "inklusive");
        }
        break;
      case "shower":
        if (zeroornot(event.target.value)) {
          updatePrice("shower", "inklusive");
        }
        break;
      case "wc":
        if (zeroornot(event.target.value)) {
          updatePrice("wc", "inklusive");
        }
        break;
      case "casset":
        if (zeroornot(event.target.value)) {
          updatePrice("casset", "inklusive");
        }
        break;
      case "ground":
        if (zeroornot(event.target.value)) {
          updatePrice("ground", "inklusive");
        }
        break;
      case "wlan":
        if (zeroornot(event.target.value)) {
          updatePrice("wlan", "inklusive");
        }
        break;
      case "trashbin":
        if (zeroornot(event.target.value)) {
          updatePrice("trashbin", "inklusive");
        }
        break;
      case "wash":
        if (zeroornot(event.target.value)) {
          updatePrice("wash", "inklusive");
        }
        break;
    }
  };

  const handleChange = (event: { target: { value: string; name: string } }) => {
    switch (event.target.name) {
      case "zweipersonen":
        if (event.target.value !== "Kostenlos") {
          setStatus({ ...status, zweipersonen: false });
        }
        // if (event.target.value === "0") {
        //   // event.target.value = "Kostenlos";
        //   updatePrice("zweipersonen", "Kostenlos");
        // } else {
        updatePrice("zweipersonen", event.target.value);
        // }
        break;
      case "sidezweipersonen":
        if (event.target.value !== "Kostenlos") {
          setStatus({ ...status, sidezweipersonen: false });
        }
        // if (event.target.value === "0") {
        //   // event.target.value = "Kostenlos";
        //   updatePrice("zweipersonen", "Kostenlos");
        // } else {
        updatePrice("sidezweipersonen", event.target.value);
        // }
        break;
      case "properson":
        if (event.target.value !== "inklusive") {
          setStatus({ ...status, proPerson: false });
        }
        // if (event.target.value === "0") {
        //   // event.target.value = "Kostenlos";
        //   updatePrice("proPerson", "inklusive");
        // } else {
        updatePrice("proPerson", event.target.value);
        // }
        break;
      case "prokid":
        if (event.target.value !== "inklusive") {
          setStatus({ ...status, proKid: false });
        }
        // if (event.target.value === "0") {
        //   // event.target.value = "Kostenlos";
        //   updatePrice("proKid", "inklusive");
        // } else {
        updatePrice("proKid", event.target.value);
        // }
        break;
      case "propet":
        changeSix(event, "proPet");
        break;
      case "taxperson":
        changeSix(event, "taxPerson");
        break;
      case "taxkid":
        changeSix(event, "taxKid");
        break;
      case "taxpet":
        changeSix(event, "taxPet");
        break;
      case "electricity":
        changeSix(event, "electricity");
        break;
      case "water":
        changeSix(event, "water");
        break;
      case "shower":
        changeSix(event, "shower");
        break;
      case "wc":
        changeSix(event, "wc");
        break;
      case "casset":
        changeSix(event, "casset");
        break;
      case "ground":
        changeSix(event, "ground");
        break;
      case "wlan":
        changeSix(event, "wlan");
        break;
      case "trashbin":
        changeSix(event, "trashbin");
        break;
      case "wash":
        changeSix(event, "wash");
        break;
      case "barrier":
        updatePrice("barrier", event.target.value);
        break;
      case "playground":
        updatePrice("playground", event.target.value);
        break;
      default:
        console.log("No such thing exists!");
        break;
    }
  };
  const reloadState = (id: any) => {
    const data = id.data as string;
    setStatus({ ...status, [data]: true });
    updatePrice(data, priceLoad[data] as string);
    if (
      data === "sidezweipersonen" ||
      data === "zweipersonen" ||
      data === "proPerson" ||
      data === "proKid"
    ) {
      switch (data) {
        case "zweipersonen":
          setzPerson(true);
          break;
        case "sidezweipersonen":
          setszPerson(true);
          break;
        case "proPerson":
          setpPerson(true);
          break;
        case "proKid":
          setpChild(true);
          break;
        default:
          break;
      }
    } else {
      if (id.status && id.status === true) {
        setSecondDropdown({ ...secondDropdown, [data]: true });
        // setSecondDropdown({ ...secondDropdown, [data]: false });
      }
    }
  };

  const BackButton = (data: any) => (
    <IconButton onClick={() => reloadState(data)}>
      <ArrowDropDownIcon />
    </IconButton>
  );
  return (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Preisinformationen:
        </Typography>
      </Grid>
      {/* <Grid container item>
        <PriceHint />
        
        </Grid> */}
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: zPerson,
              onOpen: () => setzPerson(true),
              onClose: () => setzPerson(false),
            }}
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.zweipersonen === priceOld.zweipersonen
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.zweipersonen === priceOld.zweipersonen
                  ? false
                  : true
                : false
            }
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            id="zweipersonen"
            onBlur={handleBlur}
            select={status.zweipersonen}
            placeholder="0,00 €"
            label={!customer ? "*Hauptsaison:" : "Hauptsaison:"}
            value={price.zweipersonen}
            name="zweipersonen"
            error={
              !customer
                ? submitted && price.zweipersonen.length === 0
                  ? true
                  : false
                : false
            }
            onChange={handleChange}
            InputProps={
              status.zweipersonen === false
                ? { endAdornment: <BackButton data="zweipersonen" /> }
                : {}
            }
          >
            {persons.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: szPerson,
              onOpen: () => setszPerson(true),
              onClose: () => setszPerson(false),
            }}
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.sidezweipersonen === priceOld.sidezweipersonen
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.sidezweipersonen === priceOld.sidezweipersonen
                  ? false
                  : true
                : false
            }
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            id="sidezweipersonen"
            onBlur={handleBlur}
            select={status.sidezweipersonen}
            placeholder="0,00 €"
            label={!customer ? "*Nebensaison:" : "Nebensaison:"}
            value={price.sidezweipersonen}
            name="sidezweipersonen"
            error={
              !customer
                ? submitted && price.sidezweipersonen.length === 0
                  ? true
                  : false
                : false
            }
            onChange={handleChange}
            InputProps={
              status.sidezweipersonen === false
                ? { endAdornment: <BackButton data="sidezweipersonen" /> }
                : {}
            }
          >
            {persons.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: pPerson,
              onOpen: () => setpPerson(true),
              onClose: () => setpPerson(false),
            }}
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.proPerson === priceOld.proPerson
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.proPerson === priceOld.proPerson
                  ? false
                  : true
                : false
            }
            fullWidth
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="properson"
            select={status.proPerson}
            placeholder="0,00 €"
            label={!customer ? "*pro weitere Person:" : "pro weitere Person:"}
            value={price.proPerson}
            name="properson"
            onChange={handleChange}
            error={
              !customer
                ? submitted && price.proPerson.length === 0
                  ? true
                  : false
                : false
            }
            InputProps={
              status.proPerson === false
                ? { endAdornment: <BackButton data="proPerson" /> }
                : {}
            }
          >
            {includes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: pChild,
              onOpen: () => setpChild(true),
              onClose: () => setpChild(false),
            }}
            error={
              !customer
                ? submitted && price.proKid.length === 0
                  ? true
                  : false
                : false
            }
            color={
              admin === true
                ? price.proKid === priceOld.proKid
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.proKid === priceOld.proKid
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            onBlur={handleBlur}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="prokid"
            select={status.proKid}
            label={!customer ? "*pro Kind:" : "pro Kind:"}
            placeholder="0,00 €"
            value={price.proKid}
            name="prokid"
            onChange={handleChange}
            InputProps={
              status.proKid === false
                ? { endAdornment: <BackButton data="proKid" /> }
                : {}
            }
          >
            {includes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.proPet,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["proPet"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["proPet"]: false }),
            }}
            error={
              !customer
                ? submitted && price.proPet.length === 0
                  ? true
                  : false
                : false
            }
            color={
              admin === true
                ? price.proPet === priceOld.proPet
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.proPet === priceOld.proPet
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="propet"
            select={status.proPet}
            label={!customer ? "*pro Hund:" : "pro Hund:"}
            onBlur={handleBlur}
            value={price.proPet}
            placeholder="0,00 €"
            name="propet"
            onChange={handleChange}
            InputProps={
              status.proPet === false
                ? { endAdornment: <BackButton data="proPet" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}

            {/* {secondDropdown.proPet === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}></Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={4}></Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.taxPerson,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["taxPerson"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["taxPerson"]: false }),
            }}
            error={
              !customer
                ? submitted && price.taxPerson.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.taxPerson === priceOld.taxPerson
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.taxPerson === priceOld.taxPerson
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="taxperson"
            select={status.taxPerson}
            label={!customer ? "*Kurtaxe pro Person:" : "Kurtaxe pro Person:"}
            value={price.taxPerson}
            name="taxperson"
            placeholder="0,00 €"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.taxPerson === false
                ? {
                    endAdornment: <BackButton data="taxPerson" status={true} />,
                  }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.taxPerson === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.taxKid,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["taxKid"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["taxKid"]: false }),
            }}
            error={
              !customer
                ? submitted && price.taxKid.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.taxKid === priceOld.taxKid
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.taxKid === priceOld.taxKid
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="taxkid"
            select={status.taxKid}
            label={!customer ? "*Kurtaxe pro Kind:" : "Kurtaxe pro Kind:"}
            placeholder="0,00 €"
            value={price.taxKid}
            name="taxkid"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.taxKid === false
                ? { endAdornment: <BackButton data="taxKid" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.taxKid === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.taxPet,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["taxPet"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["taxPet"]: false }),
            }}
            error={
              !customer
                ? submitted && price.taxPet.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.taxPet === priceOld.taxPet
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.taxPet === priceOld.taxPet
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="taxpet"
            select={status.taxPet}
            label={!customer ? "*Kurtaxe pro Hund:" : "Kurtaxe pro Hund:"}
            placeholder="0,00 €"
            value={price.taxPet}
            name="taxpet"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.taxPet === false
                ? { endAdornment: <BackButton data="taxPet" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.taxPet === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.electricity,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["electricity"]: true }),
              onClose: () =>
                setSecondDropdown({
                  ...secondDropdown,
                  ["electricity"]: false,
                }),
            }}
            error={
              !customer
                ? submitted && price.electricity.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.electricity === priceOld.electricity
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.electricity === priceOld.electricity
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="electricity"
            select={status.electricity}
            label={!customer ? "*Strom:" : "Strom:"}
            placeholder="0,00 €"
            value={price.electricity}
            name="electricity"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.electricity === false
                ? {
                    endAdornment: (
                      <BackButton data="electricity" status={true} />
                    ),
                  }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.electricity === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.water,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["water"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["water"]: false }),
            }}
            error={
              !customer
                ? submitted && price.water.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.water === priceOld.water
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.water === priceOld.water
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="water"
            select={status.water}
            placeholder="0,00 €"
            label={!customer ? "*Wasser:" : "Wasser:"}
            value={price.water}
            name="water"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.water === false
                ? { endAdornment: <BackButton data="water" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.water === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.shower,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["shower"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["shower"]: false }),
            }}
            error={
              !customer
                ? submitted && price.shower.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.shower === priceOld.shower
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.shower === priceOld.shower
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="shower"
            select={status.shower}
            placeholder="0,00 €"
            label={!customer ? "*Dusche:" : "Dusche:"}
            value={price.shower}
            name="shower"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.shower === false
                ? { endAdornment: <BackButton data="shower" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.shower === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.wc,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["wc"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["wc"]: false }),
            }}
            error={
              !customer
                ? submitted && price.wc.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.wc === priceOld.wc
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true ? (price.wc === priceOld.wc ? false : true) : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="wc"
            select={status.wc}
            placeholder="0,00 €"
            label={!customer ? "*WC:" : "WC:"}
            value={price.wc}
            name="wc"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.wc === false
                ? { endAdornment: <BackButton data="wc" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.wc === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.casset,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["casset"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["casset"]: false }),
            }}
            error={
              !customer
                ? submitted && price.casset.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            color={
              admin === true
                ? price.casset === priceOld.casset
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.casset === priceOld.casset
                  ? false
                  : true
                : false
            }
            variant="outlined"
            id="casset"
            select={status.casset}
            label={!customer ? "*Kassettenentsorgung:" : "Kassettenentsorgung:"}
            value={price.casset}
            placeholder="0,00 €"
            name="casset"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.casset === false
                ? { endAdornment: <BackButton data="casset" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.casset === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.ground,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["ground"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["ground"]: false }),
            }}
            error={
              !customer
                ? submitted && price.ground.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.ground === priceOld.ground
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.ground === priceOld.ground
                  ? false
                  : true
                : false
            }
            fullWidth
            variant="outlined"
            id="ground"
            select={status.ground}
            placeholder="0,00 €"
            label={!customer ? "*Bodenentsorgung:" : "Bodenentsorgung:"}
            InputLabelProps={{ shrink: true }}
            value={price.ground}
            name="ground"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.ground === false
                ? { endAdornment: <BackButton data="ground" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.ground === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.wlan,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["wlan"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["wlan"]: false }),
            }}
            error={
              !customer
                ? submitted && price.wlan.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.wlan === priceOld.wlan
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.wlan === priceOld.wlan
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="wlan"
            select={status.wlan}
            label={!customer ? "*WLAN:" : "WLAN:"}
            value={price.wlan}
            placeholder="0,00 €"
            name="wlan"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.wlan === false
                ? { endAdornment: <BackButton data="wlan" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.wlan === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.trashbin,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["trashbin"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["trashbin"]: false }),
            }}
            error={
              !customer
                ? submitted && price.trashbin.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.trashbin === priceOld.trashbin
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.trashbin === priceOld.trashbin
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="trashbin"
            select={status.trashbin}
            label="*Müllentsorgung:"
            placeholder="0,00 €"
            value={price.trashbin}
            name="trashbin"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.trashbin === false
                ? { endAdornment: <BackButton data="trashbin" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.trashbin === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            SelectProps={{
              open: secondDropdown.wash,
              onOpen: () =>
                setSecondDropdown({ ...secondDropdown, ["wash"]: true }),
              onClose: () =>
                setSecondDropdown({ ...secondDropdown, ["wash"]: false }),
            }}
            error={
              !customer
                ? submitted && price.wash.length === 0
                  ? true
                  : false
                : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.wash === priceOld.wash
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.wash === priceOld.wash
                  ? false
                  : true
                : false
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            id="wash"
            select={status.wash}
            label={!customer ? "*Waschmaschine:" : "Waschmaschine:"}
            value={price.wash}
            placeholder="0,00 €"
            name="wash"
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={
              status.wash === false
                ? { endAdornment: <BackButton data="wash" status={true} /> }
                : {}
            }
          >
            {threeChoices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            {/* {secondDropdown.wash === false
              ? yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              : includes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
          </TextField>
        </Grid>
        {/* <Grid item sm={2}>
          <TextField
            // error={submitted && price.barrier.length === 0 ? true : false}
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            id="barrier"
            select={status.barrier}
            label="Barrierefreiheit:"
            value={price.barrier}
            name="barrier"
            onChange={handleChange}
          >
            {yesno.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={2}>
          <TextField
            // error={submitted && price.playground.length === 0 ? true : false}
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            id="playground"
            select={status.playground}
            label="Spielplatz:"
            value={price.playground}
            name="playground"
            onChange={handleChange}
          >
            {yesno.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        <Grid item sm={6}></Grid>
      </Grid>
    </Grid>
  );
}
