import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { formStore, FormState } from "../State/state";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileDescriptionType } from "../Types/types";

export const ImageCard = () => {
  const files: FormState["files"] = formStore((state) => state.files);
  const fileDescription: FormState["fileDescription"] = formStore(
    (state) => state.fileDescription
  );
  const updateFileDescription: FormState["updateFileDescription"] = formStore(
    (state) => state.updateFileDescription
  );

  const updateFileOrder: FormState["updateFileOrder"] = formStore(
    (state) => state.updateFileOrder
  );
  const removeFileDescription: FormState["removeFileDescription"] = formStore(
    (state) => state.removeFileDescription
  );
  const removeFile: FormState["removeFile"] = formStore(
    (state) => state.removeFile
  );

  const handleChangeState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id: string
  ) => {
    updateFileDescription(event.target.value, id);
  };

  const handleDelete = (id: string) => {
    removeFileDescription(id);
    removeFile(id);
  };
  const handleUpdateOrder = (e: any, id: string) => {
    updateFileOrder(e.target.value, id);
  };

  useEffect(() => {}, [fileDescription]);

  const findValue = (id: string) => {
    const found: any = fileDescription.filter(
      (fileDescription) => fileDescription.id === id
    )[0];
    return found !== undefined ? found.order : "";
  };

  const findDescription = (id: string) => {
    const found: any = fileDescription.filter(
      (fileDescription) => fileDescription.id === id
    )[0];
    return found !== undefined ? found.description : "";
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {files.map((file: any, v: number) => (
          <Grid item xs={12} sm={12} md={6} xl={4} key={file.name + v}>
            <Card sx={{ maxWidth: 700 }}>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ padding: "20px 10px 20px 15px" }}
                >
                  <CardMedia
                    component="img"
                    width="120"
                    height="150"
                    src={file.preview}
                    alt="Photo"
                    style={{ borderRadius: "7px" }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ paddingBottom: "5px" }}
                      >
                        {/* <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                          color="secondary"
                        >
                          Bildbeschreibung
                        </Typography> */}
                        <TextField
                          multiline
                          placeholder="Bildtitel"
                          maxRows={4}
                          minRows={4}
                          fullWidth={true}
                          size="small"
                          value={findDescription(file.name)}
                          onChange={(e) => handleChangeState(e, file.name)}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography
                          style={{ paddingTop: "8px", paddingRight: "8px" }}
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                          color="secondary"
                          noWrap={true}
                        >
                          Reihenfolge:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Select
                          labelId="simple-select-label"
                          id="simple-select"
                          // fullWidth={true}
                          size="small"
                          value={findValue(file.name)}
                          //   label="Reihenfolge"
                          onChange={(e) => handleUpdateOrder(e, file.name)}
                        >
                          {fileDescription.map(
                            (fileDescription: any, v: number) => (
                              <MenuItem
                                value={v + 1}
                                key={fileDescription.order + v}
                              >
                                {v + 1}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <IconButton
                          aria-label="delete"
                          color="warning"
                          onClick={() => handleDelete(file.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};
