import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function MiddleHint() {
  return (
    <Grid container spacing={0} style={{marginBottom: "-20px"}}>
    <Grid container item style={{paddingTop: "5px"}}>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        align="left"
        color="secondary"
      >
      </Typography>
    </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Bitte klicken Sie die Icons an, die für Ihren Stellplatz zutreffen.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          align="left"
          color="secondary"
        >
          Die von Ihnen ausgewählten Icons (grün) werden nach Ihrer Freigabe auf www.camp24checkin.de angezeigt.
        </Typography>
      </Grid>
    </Grid>
  );
}
