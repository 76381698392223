import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { formStore, FormState } from "../State/state";
import { Alert, Button, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WithRegisteringHint from "./Hints/withRegistering";

export default function Register() {
  let navigate = useNavigate();
  const handleChangeState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateRegister(event);
  };
  const updateRegister: FormState["updateRegister"] = formStore(
    (state) => state.updateRegister
  );

  const registerEmail: FormState["registerEmail"] = formStore(
    (state) => state.registerEmail
  );
  const register: FormState["register"] = formStore((state) => state.register);
  const successRegister: FormState["successRegister"] = formStore(
    (state) => state.successRegister
  );
  const errorRegister: FormState["errorRegister"] = formStore(
    (state) => state.errorRegister
  );
  const submittedRegister: FormState["submittedRegister"] = formStore(
    (state) => state.submittedRegister
  );
  const setWithRegister: FormState["setWithRegister"] = formStore(
    (state) => state.setWithRegister
  );
  const submitRegister: FormState["submitRegister"] = formStore(
    (state) => state.submitRegister
  );
  const submitForm: FormState["submitForm"] = formStore(
    (state) => state.submitForm
  );
  const sendRegister = async () => {
    setWithRegister(true);
    const submitform = await submitForm();
    if (submitform === true) {
      const res = await submitRegister();
      if (res === true) {
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 4000);
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <Snackbar
        open={errorRegister}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">
          Diese E-Mail konnte bereits registriert werden, bitte versuchen Sie es
          mit einer anderen E-Mail
        </Alert>
      </Snackbar>

      <Snackbar
        open={successRegister}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Benutzer hinzugefügt.</Alert>
      </Snackbar>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div">
          Registrieren:
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="surname"
          fullWidth
          value={register.surname}
          label="Nachname:"
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={
            submittedRegister && register.surname.length === 0 ? true : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="name"
          fullWidth
          label="Vorname:"
          value={register.name}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={submittedRegister && register.name.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="email"
          fullWidth
          label="E-Mail:"
          value={register.email}
          variant="outlined"
          helperText={
            registerEmail === true ? "Überprüfen Sie Ihre Eingaben" : null
          }
          onChange={(e) => handleChangeState(e)}
          error={
            submittedRegister && register.email.length === 0 ? true : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="password"
          fullWidth
          label="Passwort:"
          value={register.password}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={
            submittedRegister && register.password.length === 0 ? true : false
          }
        />
      </Grid>
      <Grid item>
        <WithRegisteringHint />
      </Grid>
      <Grid
        item
        container
        style={{ paddingTop: "25px" }}
        justifyContent="flex-start"
      >
        <Button
          variant="contained"
          disableElevation
          size="large"
          style={{ color: "#ffffff" }}
          onClick={sendRegister}
        >
          SICHERN MIT REGISTRIERUNG
        </Button>
      </Grid>
    </Grid>
  );
}
