import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { formStore, FormState } from "../State/state";
import { Theme } from "@emotion/react";

// const useStyles: any = makeStyles((theme: Theme) =>
//   createStyles({
//     helperText: {
//       color: "red",
//     },
//   })
// );

export const Scout: React.FC = () => {
  const handleChangeState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateScout(event);
  };
  const updateScout: FormState["updateScout"] = formStore(
    (state) => state.updateScout
  );
  const scout: FormState["scout"] = formStore((state) => state.scout);
  const scoutOld: FormState["scout"] = formStore((state) => state.scoutOld);
  const edit: FormState["edit"] = formStore((state) => state.edit);
  const admin: FormState["admin"] = formStore((state) => state.admin);
  const submitted: FormState["submitted"] = formStore(
    (state) => state.submitted
  );

  // const classes = useStyles();
  return (
    <Grid container spacing={2} style={{ paddingBottom: "15px" }}>
      {admin === false && (
        <Grid item xs={3}>
          <Grid container spacing={0}>
            <Grid container item>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                color="secondary"
                align="left"
              >
                Ihr Team von CAMP/24 Check-In
              </Typography>
            </Grid>
            <Grid container item>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                color="secondary"
                align="left"
              >
                Telefon: 07731 90 72 610
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={2}>
        <TextField
          id="scoutName"
          fullWidth
          label="Scout:"
          variant="outlined"
          value={scout.scoutName}
          disabled
          color={
            admin === true
              ? scout.id === scoutOld.id
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true ? (scout.id === scoutOld.id ? false : true) : false
          }
          // onChange={(e) => handleChangeState(e)}
          // error={submitted && contact.street.length === 0 ? true : false}
        />
      </Grid>

      <Grid item xs={2}>
        <TextField
          id="contactPerson"
          fullWidth
          label="Ansprechpartner:"
          variant="outlined"
          value={scout.contactPerson}
          FormHelperTextProps={{ style: { color: "red", fontSize: "initial" } }}
          onChange={(e) => handleChangeState(e)}
          helperText="wird nicht veröffentlicht"
          // FormHelperTextProps={{
          //   className: classes.helperText,
          // }}
          color={
            admin === true
              ? scout.contactPerson === scoutOld.contactPerson
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? scout.contactPerson === scoutOld.contactPerson
                ? false
                : true
              : false
          }
          // error={submitted && contact.street.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="contactNumber"
          fullWidth
          label="Telefonnummer:"
          variant="outlined"
          value={scout.contactNumber}
          onChange={(e) => handleChangeState(e)}
          helperText="wird nicht veröffentlicht"
          FormHelperTextProps={{ style: { color: "red", fontSize: "initial" } }}
          color={
            admin === true
              ? scout.contactNumber === scoutOld.contactNumber
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? scout.contactNumber === scoutOld.contactNumber
                ? false
                : true
              : false
          }
          // error={submitted && contact.phone.length === 0 ? true : false}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          id="contactEmail"
          fullWidth
          label="Email:"
          variant="outlined"
          value={scout.contactEmail}
          onChange={(e) => handleChangeState(e)}
          helperText="wird nicht veröffentlicht"
          FormHelperTextProps={{ style: { color: "red", fontSize: "initial" } }}
          color={
            admin === true
              ? scout.contactEmail === scoutOld.contactEmail
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? scout.contactEmail === scoutOld.contactEmail
                ? false
                : true
              : false
          }
          // error={submitted && contact.phone.length === 0 ? true : false}
        />
      </Grid>
    </Grid>
  );
};
