import { ActivitiesList, ActivitiesListEn } from "../../Data/tooltips";
import { ActivitiesImages } from "../../Utils/helpers";
import { Checkbox, Tooltip, Typography, Zoom, Grid } from "@mui/material";
import { formStore, FormState } from "../../State/state";

export default function Activities() {
  // const { updateActivities, activities } = formStore();
  // ? this could have positive impact on performance
  const updateActivities: FormState["updateActivities"] = formStore(
    (state) => state.updateActivities
  );
  const activities: FormState["activities"] = formStore(
    (state) => state.activities
  );
  const activitiesOld: FormState["activities"] = formStore(
    (state) => state.activitiesOld
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateActivities(ActivitiesListEn[v], e.currentTarget.checked);
  };
  const admin = formStore((state) => state.admin);

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Aktivitäten:
        </Typography>
      </Grid>
      {ActivitiesList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={activities[ActivitiesListEn[v]]}
            id={option + v}
            color="primary"
            icon={
              <img
                src={ActivitiesImages["Gray/" + v + ".png"]}
                height={48}
                width={48}
                alt="activities"
                style={
                  admin === true
                    ? activities[ActivitiesListEn[v]] !==
                      activitiesOld[ActivitiesListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
            checkedIcon={
              <img
                src={ActivitiesImages["Green/" + v + "_g.png"]}
                height={48}
                width={48}
                alt="activities"
                style={
                  admin === true
                    ? activities[ActivitiesListEn[v]] !==
                      activitiesOld[ActivitiesListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
