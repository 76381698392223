export const persons = [
  {
    value: "Kostenlos",
    label: "Kostenlos",
  },
  {
    value: "",
    label: "Preis",
  },
];

export const season = [
  {
    value: "Ganzjährig",
    label: "Ganzjährig",
  },
  {
    value: "",
    label: "Text",
  },
];

export const arrival = [
  {
    value: "Durchgehend",
    label: "Durchgehend",
  },
  {
    value: "",
    label: "Text",
  },
];

export const departure = [
  {
    value: "Durchgehend",
    label: "Durchgehend",
  },
  {
    value: "",
    label: "Text",
  },
];

export const includes = [
  {
    value: "inklusive",
    label: "inklusive",
  },
  {
    value: "",
    label: "Preis",
  },
];

export const yesno = [
  {
    value: "Ja",
    label: "Ja",
  },
  {
    value: "Nein",
    label: "Nein",
  },
];

export const threeChoices = [
  {
    value: "Nein",
    label: "Nein",
  },
  {
    value: "inklusive",
    label: "inklusive",
  },
  {
    value: "",
    label: "Preis",
  },
];

export const stay = [
  {
    value: "Unbegrenzt",
    label: "Unbegrenzt",
  },
  {
    value: "",
    label: "Tage",
  },
];
