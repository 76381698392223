import { RentList, RentListEn } from "../../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Typography, Grid } from "@mui/material";
import { RentImages } from "../../Utils/helpers";
import { formStore, FormState } from "../../State/state";

export default function Rent() {
  // const { updateRent, rent } = formStore();
  const updateRent: FormState["updateRent"] = formStore(
    (state) => state.updateRent
  );

  const rentOld: FormState["rent"] = formStore((state) => state.rentOld);
  const admin = formStore((state) => state.admin);
  const rent: FormState["rent"] = formStore((state) => state.rent);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateRent(RentListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Mietunterkünfte:
        </Typography>
      </Grid>
      {RentList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={rent[RentListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={RentImages["Gray/" + v + ".png"]}
                height={48}
                width={48}
                alt="rent"
                style={
                  admin === true
                    ? rent[RentListEn[v]] !== rentOld[RentListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
            checkedIcon={
              <img
                src={RentImages["Green/" + v + "_g.png"]}
                height={48}
                width={48}
                alt="rent"
                style={
                  admin === true
                    ? rent[RentListEn[v]] !== rentOld[RentListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
