import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function BottomHint() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Bitte laden Sie hier Bilder Ihres Stellplatzes und noch lieber von den
          Sehenswürdigkeiten in Ihrer Umgebung hoch.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Teilen Sie mit Ihren künftigen Gästen die Plätze, die einen Besuch
          wert sind.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          (Max. 10 Bilder mit max. je 5 MB sind möglich, gerne als .png, .jpg
          oder .jpeg)
        </Typography>
      </Grid>
    </Grid>
  );
}
