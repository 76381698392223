import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { formStore, FormState } from "../State/state";
import { Theme } from "@emotion/react";
import { FormControlLabel, Switch } from "@mui/material";

// const useStyles: any = makeStyles((theme: Theme) =>
//   createStyles({
//     helperText: {
//       color: "red",
//     },
//   })
// );

export const Switches: React.FC = () => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "camp") {
      // console.log("isCamp");
      setCamp(event.target.checked);
      setPark(!event.target.checked);
    } else if (event.target.name === "park") {
      setPark(event.target.checked);
      setCamp(!event.target.checked);
    }
  };

  const admin: FormState["admin"] = formStore((state) => state.admin);
  const isCamp: FormState["isCamp"] = formStore((state) => state.isCamp);
  const isPark: FormState["isPark"] = formStore((state) => state.isPark);
  const isCampOld: FormState["isCampOld"] = formStore(
    (state) => state.isCampOld
  );
  const isParkOld: FormState["isParkOld"] = formStore(
    (state) => state.isParkOld
  );
  const setCamp: FormState["setCamp"] = formStore((state) => state.setCamp);
  const setPark: FormState["setPark"] = formStore((state) => state.setPark);

  // const classes = useStyles();
  return (
    <Grid container spacing={2} style={{ paddingBottom: "15px" }}>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={isCamp}
              onChange={(e) => handleChange(e)}
              name="camp"
              color={
                admin === true
                  ? isCamp === isCampOld
                    ? "primary"
                    : "warning"
                  : "primary"
              }
              // focused={
              //   admin === true ? (isCamp === isCampOld ? false : true) : false
              // }
            />
          }
          label="Campingplatz"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={isPark}
              onChange={(e) => handleChange(e)}
              name="park"
              color={
                admin === true
                  ? isPark === isParkOld
                    ? "primary"
                    : "warning"
                  : "primary"
              }
              // focused={
              //   admin === true ? (isPark === isParkold ? false : true) : false
              // }
            />
          }
          label="Stellplatz"
        />
      </Grid>
    </Grid>
  );
};
