import { Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function TopHint() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Danke, dass Sie uns helfen, die Informationen zu diesem Stell- /
          Campingplatz zu aktualisieren.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Bitte füllen Sie die nachstehenden Felder möglichst vollständig aus
          bzw. korrigieren Sie diese bei Bedarf.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Nachdem Sie korrigierte Daten eingereicht haben, werden diese von uns
          manuell überprüft. Erst nach dem Qualitäts-Check werden die
          aktualisierten Daten (innerhalb von 48 Stunden) angezeigt.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Damit ist sichergestellt, dass alles korrekt ist – ganz im Sinne
          zufriedener Gäste.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Falls Sie Hilfe bei der Bearbeitung benötigen sehen Sie sich gerne
          unser
          <Link
            underline="hover"
            href="https://www.youtube.com/watch?v=BnZ0ebTliPE"
            target={"_blank"}
          >
            {" Youtube - Video "}
          </Link>
          mit einer Schritt-für-Schritt Anleitung an.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Falls Sie Fragen haben, melden Sie sich gerne bei uns.
        </Typography>
      </Grid>
    </Grid>
  );
}

export function TopHintAlternative() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Bitte füllen Sie die nachstehenden Felder möglichst vollständig aus.
          Nachdem Sie Daten für den neuen Stell-/ Campingplatz eingereicht
          haben, werden diese von uns manuell überprüft. Erst nach dem
          Qualitäts-Check werden die aktualisierten Daten angezeigt.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Damit ist sichergestellt, dass alles korrekt ist – ganz im Sinne
          zufriedener Gäste
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Vielen Dank für Ihre Mithilfe!
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Falls Sie Hilfe bei der Bearbeitung benötigen sehen Sie sich gerne
          unser
          <Link
            underline="hover"
            href="https://www.youtube.com/watch?v=BnZ0ebTliPE"
            target={"_blank"}
          >
            {" Youtube - Video "}
          </Link>
          mit einer Schritt-für-Schritt Anleitung an.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Falls Sie weitere Fragen haben, melden Sie sich gerne bei uns.
        </Typography>
      </Grid>
    </Grid>
  );
}

export function TopHintTail() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Ihr Team von CAMP/24 Check-In
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Telefon: 07731 90 72 610
        </Typography>
      </Grid>
    </Grid>
  );
}
