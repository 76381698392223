import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { formStore, FormState } from "../State/state";
import MenuItem from "@mui/material//MenuItem";

export default function Contact() {
  const handleChangeState = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.name === "state") {
      event.target.id = "state";
    }
    updateContact(event);
  };
  const updateContact: FormState["updateContact"] = formStore(
    (state) => state.updateContact
  );
  const contact: FormState["contact"] = formStore((state) => state.contact);

  const admin: FormState["admin"] = formStore((state) => state.admin);
  const customer: FormState["customer"] = formStore((state) => state.customer);
  const contactOld: FormState["contactOld"] = formStore(
    (state) => state.contactOld
  );
  const submitted: FormState["submitted"] = formStore(
    (state) => state.submitted
  );
  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div">
          Kontaktinformationen:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="name"
          fullWidth
          color={
            admin === true
              ? contact.name === contactOld.name
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.name === contactOld.name
                ? false
                : true
              : false
          }
          label="*Name:"
          value={contact.name}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={submitted && contact.name.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="street"
          color={
            admin === true
              ? contact.street === contactOld.street
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.street === contactOld.street
                ? false
                : true
              : false
          }
          fullWidth
          value={contact.street}
          label="*Strasse und Hausnummer:"
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={submitted && contact.street.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="phone"
          color={
            admin === true
              ? contact.phone === contactOld.phone
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.phone === contactOld.phone
                ? false
                : true
              : false
          }
          fullWidth
          label={(!customer ? "*" : "") + "Telefon:"}
          value={contact.phone}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={
            !customer
              ? submitted && contact.phone.length === 0
                ? true
                : false
              : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="postCode"
          color={
            admin === true
              ? contact.postCode === contactOld.postCode
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.postCode === contactOld.postCode
                ? false
                : true
              : false
          }
          fullWidth
          label="*PLZ:"
          value={contact.postCode}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={submitted && contact.postCode.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="email"
          color={
            admin === true
              ? contact.email === contactOld.email
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.email === contactOld.email
                ? false
                : true
              : false
          }
          fullWidth
          label={(!customer ? "*" : "") + "E-Mail:"}
          value={contact.email}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={
            !customer
              ? submitted && contact.email.length === 0
                ? true
                : false
              : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="city"
          color={
            admin === true
              ? contact.city === contactOld.city
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.city === contactOld.city
                ? false
                : true
              : false
          }
          fullWidth
          label="*Ort:"
          value={contact.city}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={submitted && contact.city.length === 0 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="homepage"
          color={
            admin === true
              ? contact.homepage === contactOld.homepage
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.homepage === contactOld.homepage
                ? false
                : true
              : false
          }
          fullWidth
          value={contact.homepage}
          label={(!customer ? "*" : "") + "Homepage:"}
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          error={
            !customer
              ? submitted && contact.homepage.length === 0
                ? true
                : false
              : false
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="stater"
          color={
            admin === true
              ? contact.state === contactOld.state
                ? "primary"
                : "warning"
              : "primary"
          }
          focused={
            admin === true
              ? contact.state === contactOld.state
                ? false
                : true
              : false
          }
          value={contact.state}
          select={true}
          fullWidth
          label="*Land:"
          name="state"
          variant="outlined"
          onChange={(e) => handleChangeState(e)}
          // error={submitted && contact.state.length === 0 ? true : false}
        >
          <MenuItem key={"de"} value={"DE"}>
            Deutschland
          </MenuItem>
          <MenuItem key={"be"} value={"BE"}>
            Belgien
          </MenuItem>
          <MenuItem key={"st"} value={"AT"}>
            Österreich
          </MenuItem>
          <MenuItem key={"ch"} value={"CH"}>
            Schweiz
          </MenuItem>
          <MenuItem key={"dk"} value={"DK"}>
            Dänemark
          </MenuItem>
          <MenuItem key={"es"} value={"ES"}>
            Spanien
          </MenuItem>
          <MenuItem key={"fi"} value={"FI"}>
            Finnland
          </MenuItem>
          <MenuItem key={"fr"} value={"FR"}>
            Frankreich
          </MenuItem>
          <MenuItem key={"gb"} value={"GB"}>
            Vereinigtes Königreich
          </MenuItem>
          <MenuItem key={"ie"} value={"IE"}>
            Irland
          </MenuItem>
          <MenuItem key={"it"} value={"IT"}>
            Italien
          </MenuItem>
          <MenuItem key={"lu"} value={"LU"}>
            Luxemburg
          </MenuItem>
          <MenuItem key={"nl"} value={"NL"}>
            Niederlande
          </MenuItem>
          <MenuItem key={"no"} value={"NO"}>
            Norwegen
          </MenuItem>
          <MenuItem key={"pt"} value={"PT"}>
            Protuqal
          </MenuItem>
          <MenuItem key={"se"} value={"SE"}>
            Schweden
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
