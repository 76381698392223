import React from "react";

interface AuthContextType {
  token: string;
  email: string;
  password: string;
  signin: (
    email: string,
    password: string,
    callback: (token: string, status: number) => void
  ) => void;
  signout: (callback: VoidFunction) => void;
}

export let AuthContext = React.createContext<AuthContextType>(null!);
