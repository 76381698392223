import { FittingsList, FittingsListEn } from "../../Data/tooltips";
import { Checkbox, Tooltip, Grid, Typography, Zoom } from "@mui/material";
import { FittingsImages } from "../../Utils/helpers";
import { formStore, FormState } from "../../State/state";

export default function Fittings() {
  // const { updateFittings, fittings } = formStore();
  const updateFittings: FormState["updateFittings"] = formStore(
    (state) => state.updateFittings
  );
  const fittings: FormState["fittings"] = formStore((state) => state.fittings);
  const fittingsOld: FormState["fittings"] = formStore(
    (state) => state.fittingsOld
  );
  const admin = formStore((state) => state.admin);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateFittings(FittingsListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Ausstattung:
        </Typography>
      </Grid>
      {FittingsList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={fittings[FittingsListEn[v]]}
            id={FittingsListEn[v]}
            color="primary"
            icon={
              <img
                src={FittingsImages["Gray/" + v + ".png"]}
                height={48}
                width={48}
                alt="fittings"
                style={
                  admin === true
                    ? fittings[FittingsListEn[v]] !==
                      fittingsOld[FittingsListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
            checkedIcon={
              <img
                src={FittingsImages["Green/" + v + "_g.png"]}
                height={48}
                width={48}
                alt="fittings"
                style={
                  admin === true
                    ? fittings[FittingsListEn[v]] !==
                      fittingsOld[FittingsListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
