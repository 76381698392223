import { SightseeingList, SightseeingListEn } from "../../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Grid, Typography } from "@mui/material";
import { SightseeingImages } from "../../Utils/helpers";
import { formStore, FormState } from "../../State/state";

export default function Sightseeing() {
  // const { updateSightseeing, sightseeing } = formStore();
  const updateSightseeing: FormState["updateSightseeing"] = formStore(
    (state) => state.updateSightseeing
  );
  const sightseeing: FormState["sightseeing"] = formStore(
    (state) => state.sightseeing
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateSightseeing(SightseeingListEn[v], e.currentTarget.checked);
  };

  const sightseeingOld: FormState["sightseeing"] = formStore(
    (state) => state.sightseeingOld
  );
  const admin = formStore((state) => state.admin);
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Sehenswürdigkeiten:
        </Typography>
      </Grid>
      {SightseeingList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={sightseeing[SightseeingListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={SightseeingImages["Gray/" + v + ".png"]}
                height={48}
                width={48}
                alt="sightseeing"
                style={
                  admin === true
                    ? sightseeing[SightseeingListEn[v]] !==
                      sightseeingOld[SightseeingListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
            checkedIcon={
              <img
                src={SightseeingImages["Green/" + v + "_g.png"]}
                height={48}
                width={48}
                alt="sightseeing"
                style={
                  admin === true
                    ? sightseeing[SightseeingListEn[v]] !==
                      sightseeingOld[SightseeingListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
