import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { season, arrival, departure } from "../Data/dropdowns";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { formStore, FormState } from "../State/state";
import { IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { generalLoad } from "../State/models";
import { stay } from "../Data/dropdowns";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export default function General() {
  const updateGeneral: FormState["updateGeneral"] = formStore(
    (state) => state.updateGeneral
  );
  const setPriceSeasonStatus: FormState["setPriceSeasonStatus"] = formStore(
    (state) => state.setPriceSeasonStatus
  );

  const setPriceArrivalStatus: FormState["setPriceArrivalStatus"] = formStore(
    (state) => state.setPriceArrivalStatus
  );
  const setPriceDepartureStatus: FormState["setPriceDepartureStatus"] =
    formStore((state) => state.setPriceDepartureStatus);
  const price: FormState["general"] = formStore((state) => state.general);
  const priceOld: FormState["generalOld"] = formStore(
    (state) => state.generalOld
  );
  const priceSeasonStatus: FormState["priceSeasonStatus"] = formStore(
    (state) => state.priceSeasonStatus
  );
  const priceArrivalStatus: FormState["priceArrivalStatus"] = formStore(
    (state) => state.priceArrivalStatus
  );
  const priceDepartureStatus: FormState["priceDepartureStatus"] = formStore(
    (state) => state.priceDepartureStatus
  );
  const edit: FormState["edit"] = formStore((state) => state.edit);
  const admin: FormState["admin"] = formStore((state) => state.admin);
  const submitted: FormState["submitted"] = formStore(
    (state) => state.submitted
  );
  const stayStatus: FormState["stayStatus"] = formStore(
    (state) => state.stayStatus
  );
  const setStayStatus: FormState["setStayStatus"] = formStore(
    (state) => state.setStayStatus
  );
  const [stayx, setStayx] = React.useState(false);
  const [seasonx, setSeasonx] = React.useState(false);
  const [arrivalx, setArrivalx] = React.useState(false);
  const [departurex, setDeparturex] = React.useState(false);
  // const [price, setPrice] = React.useState(generalLoad);
  // const [status, setStatus] = React.useState(priceSeasonStatus);
  // const { updateGeneral } = formStore();

  // useEffect(() => {
  // setPrice(generalLoad);
  // setPriceSeasonStatus(priceSeasonStatus);
  // }, []);

  const minWidth = "120px";

  const reloadState = (id: any) => {
    const data = id.data as string;
    if (data === "season") {
      setPriceSeasonStatus(true);
      setSeasonx(true);
    } else if (data === "arrival") {
      setPriceArrivalStatus(true);
      setArrivalx(true);
    } else if (data === "departure") {
      setPriceDepartureStatus(true);
      setDeparturex(true);
    } else {
      setStayStatus(true);
      setStayx(true);
    }
    // setPrice({ ...price, [data]: generalLoad[data] as string });
    updateGeneral(data, generalLoad[data] as string);
  };

  const BackButton = (data: any) => (
    <IconButton onClick={() => reloadState(data)}>
      <ArrowDropDownIcon />
    </IconButton>
  );

  const handleChange = (event: {
    target: { value: string | number; name: string };
  }) => {
    if ((event.target.value as number) < 0) {
      event.target.value = 0;
    }
    switch (event.target.name) {
      case "season":
        if (event.target.value !== "Ganzjährig") {
          setPriceSeasonStatus(false);
        }
        // setPrice({ ...price, season: event.target.value.toString() });
        updateGeneral("season", event.target.value.toString());
        // setSeasonx(false)
        break;

      case "arrival":
        if (event.target.value !== "Durchgehend") {
          setPriceArrivalStatus(false);
        }
        // setPrice({ ...price, season: event.target.value.toString() });
        updateGeneral("arrival", event.target.value.toString());
        // setSeasonx(false)
        break;
      case "departure":
        if (event.target.value !== "Durchgehend") {
          setPriceDepartureStatus(false);
        }
        // setPrice({ ...price, season: event.target.value.toString() });
        updateGeneral("departure", event.target.value.toString());
        // setSeasonx(false)
        break;
      case "parkplaceCount":
        // setPrice({ ...price, parkplaceCount: event.target.value.toString() });
        updateGeneral("parkplaceCount", event.target.value.toString());
        break;
      case "maximumLength":
        // setPrice({ ...price, maximumLength: event.target.value.toString() });
        updateGeneral("maximumLength", event.target.value.toString());
        break;
      case "maximumStay":
        if (event.target.value !== "Unbegrenzt") {
          setStayStatus(false);
        }
        if (event.target.value === "0") {
          updateGeneral("maximumStay", "Unbegrenzt");
          setStayStatus(true);
        } else {
          updateGeneral("maximumStay", event.target.value.toString());
        }
        // setPrice({ ...price, maximumStay: event.target.value.toString() });

        break;
      default:
        console.log("No such day exists!");
        break;
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Allgemeine Informationen:
        </Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6} sm={2}>
          <TextField
            error={
              submitted && price.parkplaceCount.length === 0 ? true : false
            }
            style={{ minWidth: minWidth }}
            color={
              admin === true
                ? price.parkplaceCount === priceOld.parkplaceCount
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.parkplaceCount === priceOld.parkplaceCount
                  ? false
                  : true
                : false
            }
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="parkplaceCount"
            label="Anzahl der Stellplätze:"
            type={"number"}
            value={price.parkplaceCount}
            name="parkplaceCount"
            onChange={handleChange}
          ></TextField>
        </Grid>
        {/* <Grid item xs={6} sm={2}>
          <TextField
            error={submitted && price.maximumLength.length === 0 ? true : false}
            color={
              admin === true
                ? price.maximumLength === priceOld.maximumLength
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.maximumLength === priceOld.maximumLength
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="maximumLength"
            type={"number"}
            label="Max. Fahrzeuglänge:"
            placeholder="Meter"
            value={price.maximumLength}
            name="maximumLength"
            onChange={handleChange}
          ></TextField>
        </Grid> */}
        {/*Tage  Unbegrenzt */}
        <Grid item xs={6} sm={2}>
          <TextField
            SelectProps={{
              open: stayx,
              onOpen: () => setStayx(true),
              onClose: () => setStayx(false),
            }}
            error={submitted && price.maximumStay.length === 0 ? true : false}
            color={
              admin === true
                ? price.maximumStay === priceOld.maximumStay
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.maximumStay === priceOld.maximumStay
                  ? false
                  : true
                : false
            }
            select={stayStatus.stay}
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="maximumStay"
            label="Maximaler Aufenthalt:"
            type={price.maximumStay !== "Unbegrenzt" ? "number" : "text"}
            placeholder="Tage"
            value={price.maximumStay}
            name="maximumStay"
            onChange={handleChange}
            InputProps={
              stayStatus.stay === false
                ? { endAdornment: <BackButton data="stay" /> }
                : {}
            }
          >
            {stay.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            SelectProps={{
              open: arrivalx,
              onOpen: () => setArrivalx(true),
              onClose: () => setArrivalx(false),
            }}
            error={submitted && price.season.length === 0 ? true : false}
            color={
              admin === true
                ? price.arrival === priceOld.arrival
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.arrival === priceOld.arrival
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="arrival"
            select={priceArrivalStatus.arrival}
            label="Anreise:"
            value={price.arrival}
            name="arrival"
            onChange={handleChange}
            InputProps={
              priceArrivalStatus.arrival === false
                ? { endAdornment: <BackButton data="arrival" /> }
                : {}
            }
          >
            {arrival.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            SelectProps={{
              open: departurex,
              onOpen: () => setDeparturex(true),
              onClose: () => setDeparturex(false),
            }}
            error={submitted && price.season.length === 0 ? true : false}
            color={
              admin === true
                ? price.departure === priceOld.departure
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.departure === priceOld.departure
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="departure"
            select={priceDepartureStatus.departure}
            label="Abreise:"
            value={price.departure}
            name="departure"
            onChange={handleChange}
            InputProps={
              priceDepartureStatus.departure === false
                ? { endAdornment: <BackButton data="departure" /> }
                : {}
            }
          >
            {departure.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            SelectProps={{
              open: seasonx,
              onOpen: () => setSeasonx(true),
              onClose: () => setSeasonx(false),
            }}
            error={submitted && price.season.length === 0 ? true : false}
            color={
              admin === true
                ? price.season === priceOld.season
                  ? "primary"
                  : "warning"
                : "primary"
            }
            focused={
              admin === true
                ? price.season === priceOld.season
                  ? false
                  : true
                : false
            }
            style={{ minWidth: minWidth }}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: edit || admin }}
            id="season"
            select={priceSeasonStatus.season}
            label="Saison:"
            value={price.season}
            name="season"
            onChange={handleChange}
            InputProps={
              priceSeasonStatus.season === false
                ? { endAdornment: <BackButton data="season" /> }
                : {}
            }
          >
            {season.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Grid>
  );
}
