import { ServiceList, ServiceListEn } from "../../Data/tooltips";
import { Checkbox, Tooltip, Zoom, Typography, Grid } from "@mui/material";
import { ServiceImages } from "../../Utils/helpers";
import { formStore, FormState } from "../../State/state";

export default function Service() {
  // const { updateService, service } = formStore();
  const updateService: FormState["updateService"] = formStore(
    (state) => state.updateService
  );
  const serviceOld: FormState["service"] = formStore(
    (state) => state.serviceOld
  );
  const admin = formStore((state) => state.admin);
  const service: FormState["service"] = formStore((state) => state.service);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updateService(ServiceListEn[v], e.currentTarget.checked);
  };
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Service:
        </Typography>
      </Grid>
      {ServiceList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={service[ServiceListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={ServiceImages["Gray/" + v + ".png"]}
                height={48}
                width={48}
                alt="service"
                style={
                  admin === true
                    ? service[ServiceListEn[v]] !== serviceOld[ServiceListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
            checkedIcon={
              <img
                src={ServiceImages["Green/" + v + "_g.png"]}
                height={48}
                width={48}
                alt="service"
                style={
                  admin === true
                    ? service[ServiceListEn[v]] !== serviceOld[ServiceListEn[v]]
                      ? { border: "dashed", borderColor: "#ff9800" }
                      : {}
                    : {}
                }
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
}
