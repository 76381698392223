import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { formStore, FormState } from "../State/state";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export function DropPicture(props: any) {
  const [toggle, setToggle] = useState(false);
  const [showCountAlert, setShowCountAlert] = useState(false);
  const [message, setMessage] = useState("");

  const setFiles: FormState["setFiles"] = formStore((state) => state.setFiles);
  const setBackDrop: FormState["setBackDrop"] = formStore(
    (state) => state.setBackDrop
  );
  const addFiles: FormState["addFiles"] = formStore((state) => state.addFiles);
  const setFileDescription: FormState["setFileDescription"] = formStore(
    (state) => state.setFileDescription
  );
  const files: FormState["files"] = formStore((state) => state.files);
  enum messageContent {
    "Max. 10 Bilder",
    "Max. Bildgröße überschritten.",
  }
  const alertTrigger = (i: number, content?: string) => {
    setShowCountAlert(true);
    setMessage(
      messageContent[i] +
        (content !== "" ? " Ihre Bildgröße ist " + content : "")
    );
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/png", "image/jpg"],
    maxSize: 20000000,
    onDrop: (acceptedFiles: any) => {
      // setBackDrop(true);
      // const time = new Date().getTime();
      if (files.length == 10 || files.length + acceptedFiles.length > 10) {
        alertTrigger(0);
      } else {
        let count = 0;
        let size = "";
        acceptedFiles.forEach((file: any) => {
          if (file.size > 5242880) {
            count = count + 1;
            size = (parseInt(file.size) / 1048576).toFixed(2) + " MB";
            // console.log(count);
          }
        });

        if (count > 0) {
          alertTrigger(1, size);
        } else {
          if (files.length > 0) {
            addFiles(
              acceptedFiles.map((file: any) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );
            acceptedFiles.map(async (e: any, i: number) => {
              let reader = new FileReader();
              reader.readAsDataURL(e);
              let base;
              reader.onload = await function () {
                //me.modelvalue = reader.result;
                // console.log(reader.result);
                base = reader.result;
                setFileDescription(
                  "",
                  e.name,
                  (i + files.length + 1).toString(),
                  base
                );
              };
              // let base = reader.result;
            });
          } else {
            setFiles(
              acceptedFiles.map((file: any) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              )
            );

            acceptedFiles.map(async (e: any, i: number) => {
              let reader = new FileReader();
              reader.readAsDataURL(e);
              let base;
              reader.onload = await function () {
                //me.modelvalue = reader.result;
                // console.log(reader.result);
                base = reader.result;

                setFileDescription("", e.name, (i + 1).toString(), base);
              };
              // let base = reader.result;
            });
          }

          // setToggle(!toggle);
        }
      }

      // console.log((new Date().getTime() - time) / 1000);
    },
  });

  const handleCloseCount = () => {
    setShowCountAlert(false);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // @ts-ignore
    // files.forEach((file) => URL.revokeObjectURL(file.preview));
    // @ts-ignore
    // console.log(files[0].preview);
  }, [toggle]);

  return (
    <React.Fragment>
      <Dialog
        open={showCountAlert}
        onClose={handleCloseCount}
        // fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Achtung</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCount}
            autoFocus
            variant="contained"
            style={{ color: "#ffffff" }}
            disableElevation
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Box
            sx={{
              p: 2,
              borderRadius: "15px",
              backgroundColor: "#7ED321",
              color: "#fafafa",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              Ziehen Sie einige Fotos hierher, oder klicken Sie, um Fotos
              auszuwählen.
            </Typography>
          </Box>
        </div>
        {/* <aside style={thumbsContainer as any}>{thumbs}</aside> */}
      </section>
      {/* <ImageCard /> */}
    </React.Fragment>
  );
}
