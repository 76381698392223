import { StyledEngineProvider } from "@mui/material/styles";
import "./App.css";
import AutoGrid from "./Client/Pages/layout";
import * as React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { fakeAuthProvider } from "./auth";
import { LoginPage } from "./Main/Pages/login";
import { AuthContext } from "./authcontext";
import { URL } from "./Client/Utils/helpers";

// function App() {
//   return (
//     <div className="App">
//       <StyledEngineProvider injectFirst>
//         <AutoGrid />
//       </StyledEngineProvider>
//     </div>
//   );
// }

// export default App;

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />} />
        {/* <Route path="*" element={<NotFoundPage />} /> */}
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/orx/*" element={<AutoGrid />} />
        <Route path="/orm/*" element={<AutoGrid />} />
        <Route path="/adm/*" element={<AutoGrid />} />
        <Route
          path="/form/*"
          element={
            <RequireAuth>
              <AutoGrid />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <div>
      {/* <AuthStatus /> */}
      {/* <ul>
        <li>
          <Link to="/">Public Page</Link>
        </li>
        <li>
          <Link to="/protected">Protected Page</Link>
        </li>
      </ul> */}

      <Outlet />
    </div>
  );
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  let navigate = useNavigate();
  let [user, setUser] = React.useState<any>(null);
  let [email, setEmail] = React.useState<any>(null);
  let [password, setPassword] = React.useState<any>(null);
  let [token, setToken] = React.useState<any>(null);

  let signin = (
    email: string,
    password: string,
    callback: (token: string, status: number) => void
  ) => {
    const data = { email: email, password: password };
    fetch(URL + "form/login", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          callback("", 1);
          throw new Error("Wrong username or password");
        } else if (response.status === 400) {
          callback("", 2);
          throw new Error("No user found");
        }
      })
      .then((data) => {
        setToken(data.token);
        callback(data.token, 0);
      })
      .catch((error) => {
        // fakeAuthProvider.isAuthenticated = false;
        console.error("Error:", error);
      });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  let value = { email, password, token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.token) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.token}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function PublicPage() {
  // return <h3>Public</h3>;
}

function ProtectedPage() {
  // return <h3>Protected</h3>;
}

const NotFoundPage = () => {
  return (
    <div>
      <h1 style={{ color: "red", fontSize: 100 }}>404</h1>
      <h3>Page Not Found</h3>
      <p>
        <Link to="/">Go Home</Link>
      </p>
    </div>
  );
};
