export const FittingsList = [
  "Strom",
  "Wasser",
  "WC",
  "Dusche",
  "Mietbäder",
  "WLAN",
  "Barrierefrei",
  "Hunde erlaubt",
  "Hundedusche",
  "Müllentsorgung",
  "Kassettenentsorgung",
  "Bodenentsorgung",
  "Entsorgungsautomat",
  "Wasser am Platz",
  "Abwasser am Platz",
  "TV/Anschluss",
  "Kinderspielplatz",
  "Kinderspielraum",
  "Kinder/Animation",
  "Wickelraum",
  "Aufenthaltsraum",
  "Spülmaschine",
  "Waschmaschine",
  "Wäschetrockner",
  "Trockner/Raum",
  "Wellness",
  "Sauna",
  "Massage",
  "Restaurant",
  "Kiosk",
  "Supermarkt",
  "Bäckerei",
  "Kaffee",
  "Grillplatz",
  "Wintercamping",
  "Mehrsprachig",
  "Weingut",
  "Biergarten",
  "Überlänge (ab 8 Meters)",
  "Bauernhof",
  "Wohnwagen Erlaubt",
];

export const ActivitiesList = [
  "Radfahren",
  "Mountainbiken",
  "Schwimmen",
  "Schwimmbad",
  "Wandern",
  "Angeln",
  "Paddeln",
  "Rafting",
  "Segeln",
  "Tauchen",
  "Golf",
  "Tennis",
  "Skateboard",
  "Volleyball",
  "Reiten",
  "Strand",
  "Federball",
  "Boccia",
  "Tischtennis",
  "Skifahren",
  "Langlauf",
  "Klettern",
  "Seilbahn",
  "Zahnradbahn",
  "Schifffahrt",
  "Paragleiten",
  "Ballonfahrt",
  "Safaritour",
  "FKK",
  "Therme",
  "Kanufahren",
  "Mini Golf",
  "Erlebnisbad",
  "Badesee",
];

export const SightseeingList = [
  "Aussichtspunkt",
  "Burg/Festung",
  "Historische Gebäude",
  "Museum",
  "Kirche",
  "Moschee",
  "Synagoge",
  "Fußgängerzone",
  "Einkaufszentrum",
  "Kino/Theater",
  "Zoo",
  "Stadion",
];

export const TransportList = ["Bus", "Strassenbahn", "Taxi", "Zug", "Schiff"];

export const ServiceList = [
  "Brötchenservice",
  "Tourist/Info",
  "Gas",
  "Friseur",
  "Apotheke",
  "Post",
  "Geldautomat",
  "Wäscheservice",
  "Fahrradverleih",
  "Fahrradreparatur",
  "Autoverleih",
  "Werkstatt",
  "Bootsverleih",
  "Slipanlage",
  "Tankstelle",
  "Autofrei",
];

export const RentList = [
  "Mobilheim",
  "Appartements",
  "Campingfass",
  "Hütte",
  "Wohnwagen",
  "Zelt",
  "Baumhaus",
  "Bauwagen",
  "Mietbad",
];

export const ActivitiesListEn = [
  "cycle",
  "mountainBiking",
  "poolswim",
  "swim",
  "hiking",
  "fishing",
  "paddling",
  "rafting",
  "sailing",
  "diving",
  "golf",
  "tennis",
  "snowski",
  "volleyball",
  "horsebackRiding",
  "beach",
  "badminton",
  "boccia",
  "tableTennis",
  "skiing",
  "snowhike",
  "climbing",
  "railway",
  "cogRailway",
  "ship",
  "paragliding",
  "balloonRide",
  "safariTour",
  "naturist",
  "thermalBath",
  "canoe",
  "miniGolf",
  "waterPark",
  "lakeSwimming",
];

export const FittingsListEn = [
  "electricity",
  "water",
  "toilet",
  "shower",
  "rentalBathrooms",
  "wifi",
  "handicappedAccessible",
  "pets",
  "dogShower",
  "rubbish",
  "chemistryWC",
  "disposal",
  "automat",
  "waterOnSite",
  "sewageOnSite",
  "TVconnection",
  "playground",
  "childrenPlayroom",
  "childrenAnimation",
  "babyChangingRoom",
  "recreationRoom",
  "dishwasher",
  "washingMachine",
  "laundry",
  "dryerRoom",
  "wellness",
  "sauna",
  "massage",
  "restaurant",
  "kiosk",
  "telmarkt",
  "bakery",
  "coffee",
  "barbecue",
  "winterCamping",
  "multilingual",
  "wine",
  "beerGarden",
  "overSize",
  "farm",
  "caravanAllowed",
];

export const RentListEn = [
  "mobilehome",
  "flats",
  "campingBarrel",
  "cabin",
  "caravan",
  "tent",
  "treehouse",
  "trailer",
  "rentalBathroom",
];

export const SightseeingListEn = [
  "view",
  "castle",
  "history",
  "museum",
  "church",
  "mosque",
  "synagogue",
  "zone",
  "shop",
  "movietheater",
  "zoo",
  "stadion",
];

export const TransportListEn = ["bus", "tram", "taxi", "train", "ship"];

export const ServiceListEn = [
  "breadService",
  "touristInfo",
  "gas",
  "hairdresser",
  "pharmacy",
  "postOffice",
  "cashDispenser",
  "laundryService",
  "bicycleRental",
  "bicycleRepair",
  "carRental",
  "workshop",
  "boatHire",
  "slipway",
  "petrolStation",
  "carFree",
];

export const PromotionList = [
  "ACSI",
  "ADAC",
  "Camping Key",
  "CCI/CCS",
  "DCC",
  "ÖCC",
  "TCS",
  "Campingcheque",
];

export const PromotionListEn = [
  "acsi",
  "adac",
  "campingkey",
  "ccicss",
  "dcc",
  "occ",
  "tcs",
  "cheque",
];
