import React from "react";
import { Checkbox, Tooltip, Grid, Typography, Zoom } from "@mui/material";

import { PromotionList, PromotionListEn } from "../../Data/tooltips";
import { PromotionImages } from "../../Utils/helpers";
import { formStore, FormState } from "../../State/state";

export const Promotion: React.FC = () => {
  const updatePromotion: FormState["updatePromotion"] = formStore(
    (state) => state.updatePromotion
  );
  const promotion: FormState["promotion"] = formStore(
    (state) => state.promotion
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
    updatePromotion(PromotionListEn[v], e.currentTarget.checked);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12}>
        <Typography variant="h6" gutterBottom component="div" noWrap>
          Rabatt:
        </Typography>
      </Grid>
      {PromotionList.map((option: any, v: any) => (
        <Tooltip title={option} arrow TransitionComponent={Zoom} key={option}>
          <Checkbox
            onChange={(e) => handleChange(e, v)}
            checked={promotion[PromotionListEn[v]]}
            id="stadion"
            color="primary"
            icon={
              <img
                src={PromotionImages["Gray/" + v + ".png"]}
                height={48}
                width={72}
                alt="promotion"
              />
            }
            checkedIcon={
              <img
                src={PromotionImages["Green/" + v + ".png"]}
                height={48}
                width={72}
                alt="promotion"
              />
            }
          />
        </Tooltip>
      ))}
    </Grid>
  );
};
