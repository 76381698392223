import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { fakeAuthProvider } from "../../auth";
import React from "react";
import { AuthContext } from "../../authcontext";
import {
  makeStyles,
  Container,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  Snackbar,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Logo from "../../Assets/Images/logo.png";

// const useStyles = makeStyles(theme => ({
// 	    paper: {
//         marginTop: theme.spacing(4),
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//     },
//     avatar: {
//         margin: theme.spacing(1),
//         backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//         width: '100%', // Fix IE 11 issue.
//         marginTop: theme.spacing(1),
//     },
//     // submit: {
//     //     margin: theme.spacing(3, 0, 2),
//     // },
//     cancel: {
//         marginTop: theme.spacing(0),
//     },
//     appBarLogo: {
//         height: 56,
//         marginBottom : "20px",
//         marginTop : "10px"
//     },
// }));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Camp24CheckIn "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

export function LoginPage() {
  //   const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  // @ts-ignore
  let from = location.state?.from?.pathname || "/";
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [userPasswordAlert, setUserPasswordAlert] = React.useState(false);
  const [notFoundAlert, setNotFoundAlert] = React.useState(false);

  function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    // let formData = new FormData(event.currentTarget);
    // let username = formData.get("username") as string;

    // let email = "test@example.de";
    // let password = "123456";

    auth.signin(email, password, (token: string, status: number) => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.

      //   navigate(from, { replace: true });
      if (status === 0) {
        navigate("/form/token=" + token, { replace: true });
      } else if (status === 1) {
        setUserPasswordAlert(true);
        setTimeout(() => {
          setUserPasswordAlert(false);
        }, 3000);
      } else if (status === 2) {
        setNotFoundAlert(true);
        setTimeout(() => {
          setNotFoundAlert(false);
        }, 3000);
      }

      //   navigate(from, { replace: true });
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        open={notFoundAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Konto nicht gefunden</Alert>
      </Snackbar>

      <Snackbar
        open={userPasswordAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Benutzername oder Passwort ist falsch</Alert>
      </Snackbar>
      <div
        style={{
          //   marginTop: theme.spacing(4),
          paddingTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="Camp 24"
          style={{
            width: 350,
            marginBottom: "20px",
            marginTop: "10px",
          }}
        />

        <form noValidate>
          <TextField
            // onChange={(event => setEmail(event.target.value))}
            onBlur={(event) => setEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            // onChange={(event => setPassword(event.target.value))}
            // error={this.state.invalidPassword}
            onBlur={(event) => setPassword(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/*<FormControlLabel*/}
          {/*    control={<Checkbox value="remember" color="primary" />}*/}
          {/*    label="Remember me"*/}
          {/*/>*/}
          <Button
            onClick={(event) => handleSubmit(event)}
            fullWidth
            variant="contained"
            color="primary"
            // className={classes.submit}
          >
            Anmelden
          </Button>
          <Grid container>
            {/* <Grid item xs>
					<Link href="/login/forget" variant="body2">
						Forget Password
					</Link>
				</Grid> */}
          </Grid>
        </form>
      </div>
      <br />

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    // <div>
    //   <p>You must log in to view the page at {from}</p>

    //   <form onSubmit={handleSubmit}>
    //     <label>
    //       Username: <input name="username" type="text" />
    //     </label>{" "}
    //     <button type="submit">Login</button>
    //   </form>
    // </div>
  );
}
