// import {
//   setCreateParkingArealByIdAdmin,
//   setParkingArealByIdAdmin,
// } from "../../../../../rest/AdminRestClient";

const URL = "https://meinplatz.camp24checkin.de/api/";
// const URL = "http://localhost:4001/api/";
// const URL = "https://meinplatz.camp24.xyz/api/";

export function importAll(r: any) {
  let images: any = {};
  r.keys().forEach((item: any, index: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

export const ActivitiesImages: any = importAll(
  require.context("../../Assets/Images/Activities", true, /\.(png|jpe?g|svg)$/)
);

export const FittingsImages: any = importAll(
  require.context("../../Assets/Images/Fittings", true, /\.(png|jpe?g|svg)$/)
);

export const RentImages: any = importAll(
  require.context("../../Assets/Images/Rent", true, /\.(png|jpe?g|svg)$/)
);

export const ServiceImages: any = importAll(
  require.context("../../Assets/Images/Service", true, /\.(png|jpe?g|svg)$/)
);

export const SightseeingImages: any = importAll(
  require.context("../../Assets/Images/Sightseeing", true, /\.(png|jpe?g|svg)$/)
);

export const TransportImages: any = importAll(
  require.context("../../Assets/Images/Transport", true, /\.(png|jpe?g|svg)$/)
);

export const PromotionImages: any = importAll(
  require.context("../../Assets/Images/Promotion", true, /\.(png|jpe?g|svg)$/)
);

function setParkingArealByIdAdmin(form: any) {
  let ursl = "orm";
  if (form.url === "form") {
    ursl = "form";
  } else if (form.url === "orx") {
    ursl = "orx";
  }
  fetch(URL + ursl + "/" + form.token, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  })
    .then((response) => {
      // if (response.status === 200) {
      return response.json();
      // } else if (response.status === 403) {
      // callback("", 1);
      // throw new Error("Wrong username or password");
      // } else if (response.status === 400) {
      // callback("", 2);
      // throw new Error("No user found");
      // }
    })
    .then((data) => {
      // setToken(data.token);
      // callback(data.token, 0);
    })
    .catch((error) => {
      // fakeAuthProvider.isAuthenticated = false;
      console.error("Error:", error);
    });
}

function registerOwner(form: any) {
  return fetch(URL + "reg/" + form.token, {
    method: "POST", // or 'PUT'
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  })
    .then((response) => {
      // console.log(response);
      if (response.status === 200) {
        // return response.json();
        return true;
      } else {
        return false;
        // return Promise.reject(response);
      }
    })
    .catch((error) => {
      // fakeAuthProvider.isAuthenticated = false;
      return Promise.reject(error);
      console.error("Error:", error);
    });
}

export const submitForm: any = async (
  contact: any,
  scout: any,
  price: any,
  general: any,
  activities: any,
  fittings: any,
  sightseeing: any,
  transport: any,
  token: string,
  base: any,
  url: string,
  promotion: any,
  rent: any,
  service: any,
  isCamp: boolean,
  isPark: boolean
) => {
  const form = {
    contact: contact,
    scout: scout,
    price: price,
    general: general,
    activities: activities,
    fittings: fittings,
    sightseeing: sightseeing,
    transport: transport,
    token: token,
    base: base,
    url: url,
    promotion: promotion,
    rent: rent,
    service: service,
    isCamp: isCamp,
    isPark: isPark,
  };
  return await setParkingArealByIdAdmin(form);
};

export const submitRegister: any = async (register: any, token: any) => {
  const form = {
    register: register,
    token: token,
  };
  return await registerOwner(form);
};

export const submitCreateForm: any = async (
  isBookable: boolean,
  isActive: boolean,
  contact: any,
  price: any,
  general: any,
  activities: any,
  fittings: any,
  sightseeing: any,
  transport: any,
  gps: any
) => {
  const form = {
    isBookable: isBookable,
    isActive: isActive,
    contact: contact,
    price: price,
    general: general,
    activities: activities,
    fittings: fittings,
    sightseeing: sightseeing,
    transport: transport,
    gps: gps,
  };
  // return await setCreateParkingArealByIdAdmin(form);
};

export { URL };
