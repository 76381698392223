import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function WithRegisteringHint() {
  return (
    <Grid container spacing={0}>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Mit einem Klick auf „SICHERN MIT REGISTRIERUNG“ geben Sie Ihre
          Änderungen an uns frei.
        </Typography>
      </Grid>
      <Grid container item>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          color="secondary"
          align="left"
        >
          Darüber hinaus können Sie nach der Registrierung regelmäßig und
          zeitnah Änderungen vornehmen.
        </Typography>
      </Grid>
    </Grid>
  );
}
